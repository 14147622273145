


















































































































import { Company } from '@/api/interfaces/company'
import { defineComponent } from '@vue/composition-api'
import { mapActions } from 'vuex'
import { ValidationObserver } from 'vee-validate'
import { QueryParams } from '@/store/api-plateform-utils'

export default defineComponent({
  name: 'SelectSearchCompany',
  components: {
    SelectSearch: () => import('../m-select-search.vue'),
  },
  props: {
    value: {
      type: [Object as () => Company, Array as () => Company[]],
      required: false,
      default: () => null,
    },
    label: {
      type: String,
      required: true,
      default: '',
    },
    clearable: {
      type: Boolean,
      required: false,
      default: true,
    },
    dense: {
      type: Boolean,
      required: false,
      default: false,
    },
    outlined: {
      type: Boolean,
      required: false,
      default: true,
    },
    hideDetails: {
      type: String,
      required: false,
      default: undefined,
    },
    parentCompany: {
      type: Object as () => Company,
      required: false,
      default: () => null,
    },
    businessIntroducers: {
      type: Boolean,
      required: false,
      default: false,
    },
    multiple: {
      type: Boolean,
      required: false,
      default: false,
    },
    rules: {
      type: String,
      required: false,
      default: '',
    },
    readonly: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  watch: {
    value: {
      immediate: true,
      handler () {
        if (!this.multiple && this.value && (this.value as Company).id) {
          this.companyList = [this.value as Company]
        }
      },
    },
    parentCompany () {
      if (this.parentCompany && this.parentCompany.id) {
        this.loadCompanies('')
      }
    },
  },
  data () {
    return {
      dialogCompany: false,
      company: '',
      companyList: [] as Company[],
      searching: false,
    }
  },
  methods: {
    ...mapActions('company', {
      load: 'load',
      save: 'save',
    }),
    itemText (item: Company) {
      // use this function instead of arrow function in item-text prop, workaround for https://github.com/vuetifyjs/vuetify/issues/11585
      let name = item.name
      if (item?.nameComplementary) {
        name += ' | ' + item.nameComplementary
      }
      if (item?.accountType) {
        name += ' | ' + this.$t('company.account_types.' + item.accountType)
      }
      return name
    },
    async loadCompanies (searchText: string) {
      // to not reload the first 30 company at each add of a new company when multiple is active
      if ((!searchText || searchText.length < 1) && this.multiple) {
        return
      }
      const request: QueryParams = {
        page: 1,
        itemsPerPage: 30,
      }
      if (this.parentCompany && this.parentCompany.id) {
        request.parentCompany = this.parentCompany.id
      }
      if (searchText) {
        request.searchName = searchText
      }
      if (this.businessIntroducers) {
        request['order[businessIntroducers]'] = ['desc']
      }
      this.searching = true
      this.companyList = await this.load(request)
      this.searching = false
    },
    onInput (value: string | Company[]) {
      if (this.multiple) {
        this.$emit('input', value)
      } else {
        const company = (this.companyList as Array<Company>).find(
          x => x['@id'] === value,
        )
        this.$emit('input', company)
      }
    },
    getValidationObserver () {
      return this.$refs.observer as InstanceType<typeof ValidationObserver>
    },
    async submit () {
      const formValid = this.getValidationObserver().validate()
      this.dialogCompany = false
      if (!!formValid as boolean) {
        this.$emit('input', await this.save(this.company))
      }
    },
    clear () {
      this.company = ''
      this.getValidationObserver().reset()
    },
    createCompany () {
      this.dialogCompany = !this.dialogCompany
    },
  },
})
